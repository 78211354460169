import init from "./init";
import {Contact_pc} from "../controllers/contact_pc";

class IndexPC {

    /* STATIC */
    public static async init(): Promise<void> {
        init.pushDelayed(Contact_pc);
    }

    /* INSTANCE */
}

init.push(IndexPC);
